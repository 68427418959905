<template>
  <div>
    <div class="container-pefai-cards">
      <v-row class="padding-pefai">
        <v-col cols="12">
          <span class="text-title-card">
            Origen del lead
          </span>
        </v-col>
      </v-row>
      <v-row class="padding-pefai">
        <v-col cols="4">
          <v-autocomplete
            label="Selecciona un ramo"
            outlined
            v-model="data.ramo"
            item-text="etiqueta"
            item-value="ramo"
            :items="ramos"
            color="#00a7e4"
            type="text"
            :rules="requiredRule"
          ></v-autocomplete>
        </v-col>
        <v-col cols="4" v-if="data.ramo != ''">
          <v-autocomplete
            label="Medio"
            outlined
            v-model="data.medio"
            item-text="medio"
            item-value="medio"
            :items="currentMedio"
            color="#00a7e4"
            type="text"
            :rules="requiredRule"
          ></v-autocomplete>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
import cocheLogo from "@/assets/cocheLogo.svg";
import { getRamoList } from "@/services/ramo/ramo.service.js";
import { getCampaingsList } from "@/services/campaings/campaing.service.js";

export default {
  components: {},

  data() {
    return {
      cocheLogo: cocheLogo,
      data: {
        status: "Nuevo",
        canal: "MASIVOS",
        ramo: "",
        medio: "",
        producto: "",
      },
      landings: [],
      ramos: [],
      requiredRule: [(v) => !!v || "El campo es requerido"],
    };
  },
  props: {
    getInfo: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    async getRamos() {
      this.ramos = await getRamoList();
    },

    async getCampaings() {
      var response = await getCampaingsList({});
      this.landings = response.campanias;
    },
  },
  watch: {
    "data.ramo"() {
      this.data.medio = "";
    },
    "data.medio"(newValue) {
      const selected = this.landings.find((e) => e.medio == newValue);
      this.data.producto = selected ? selected.producto : "";
    },
    getInfo(val) {
      if (val) {
        this.$emit("updateMedioData", this.data);
      }
    },
  },
  computed: {
    currentMedio() {
      return this.landings.filter(
        (campania) =>
          campania.ramo === this.data.ramo && campania.canal === this.data.canal  && campania.pefai == 1
      );
    },
  },
  mounted() {
    this.getRamos();
    this.getCampaings();
  },
};
</script>
